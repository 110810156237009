<template>
  <div class="dealer-device">
    <el-row :gutter="20">
      <el-col :span="12" class="analyze-item">
        <div class="analyze-item-container">
          <p class="analyze-item-container-title">
            设备计费
          </p>
          <el-row>
            <el-col :span="12" class="analyze-item-content">
              <p class="number">{{(400000/ 100) | F1000}}</p>
              <p class="label">本月设备租金</p>
            </el-col>
            <el-col :span="12" class="analyze-item-content">
              <p class="number">{{(400000/ 100) | F1000}}</p>
              <p class="label">本月营收金额</p>
            </el-col>
          </el-row>
        </div>

      </el-col>
      <el-col :span="12" class="analyze-item">
        <div class="analyze-item-container">
          <p class="analyze-item-container-title">
            设备运行统计
          </p>
          <el-row>
            <el-col :span="8" class="analyze-item-content">
              <p class="number online">10</p>
              <p class="label">在线设备</p>
            </el-col>
            <el-col :span="8" class="analyze-item-content">
              <p class="number offline">0</p>
              <p class="label">离线设备</p>
            </el-col>
            <el-col :span="8" class="analyze-item-content">
              <p class="number alarm">2</p>
              <p class="label">告警设备</p>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>

    <el-row class="dealer-device-filter">
      <el-col :span="4">设备列表</el-col>
      <el-col :span="10" :offset="10" class="input">
        <el-input size="mini" width="260px" placeholder="请输入设备名称/设备编号"></el-input>
        <el-button size="mini" plain type="primary">搜索</el-button>
      </el-col>
    </el-row>
    <div class="dealer-device-list">
      <el-table :data="devices">
        <empty slot="empty"></empty>
        <el-table-column label="设备信息" width="200">
          <template slot-scope="scope">
            <p> 设备编号：{{scope.row.deviceUuid}}</p>
            <p> 设备名称：{{scope.row.deviceName}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="customerName" label="客户名称" width="200" align="center"></el-table-column>
        <el-table-column prop="cups" label="本月杯量" width="80" align="center"></el-table-column>
        <el-table-column label="本月租金/营收金额" width="120">
          <template slot-scope="scope">
            {{(scope.row.rent/100) | F1000}} / {{(scope.row.seller/100) | F1000}}
          </template>
        </el-table-column>

        <el-table-column label="租赁计费模式" width="240" align="center">
          <template slot-scope="scope">
            {{scope.row.mode}}
          </template>
        </el-table-column>

        <el-table-column label="责任人" prop="dutyName"></el-table-column>

        <el-table-column label="设备状态">
          <template slot-scope="scope">
            <span class="status-btn" :class="[
              scope.row.status ==1? 'offline-bg': '',
              scope.row.status ==2? 'online-bg': '',
              scope.row.status ==3? 'alarm-bg': '',
            ]">{{scope.row.status | filterStatus}}</span>
          </template>
        </el-table-column>

        <el-table-column label="设备预警">
          <template slot-scope="scope">
            {{scope.row.alarmType | filterAlarmType}}
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="more" @click="handleDeviceDetail(scope.row)">更多</span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        :current-page="pagination.curPage"
        :page-size="pagination.limit"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>

</template>

<script>
  export default {
    name: '',
    components: {},
    mounted: function () {
    },
    filters: {
      filterAlarmType(alarmType) {
        switch (alarmType) {
          case 0:
            return '正常运行'
          case 1:
            return '停机预警'
          case 2:
            return '期望预警'
          case 3:
            return '余量预警'
          case 4:
            return '保养预警'
        }
      },

      filterStatus(status) {
        switch (status) {
          case 0:
            return '初始化'
          case 1:
            return '离线'
          case 2:
            return '在线'
          case 3:
            return '预警'
        }
      }
    },
    data: function () {
      return {
        devices: [
          {
            deviceUuid: '200017b8ead355',
            deviceName: '测试设备',
            customerName: '苏州三七二一网络科技有限公司',
            cups: 1800,
            rent: 100000,
            seller: 50000,
            mode: '固定月租、每月免费100杯、超出单杯5元',
            dutyName: '里斯本',
            status: 1,
            alarmType: 1,
          },
          {
            deviceUuid: '200017b8ead355',
            deviceName: '测试设备',
            customerName: '苏州三七二一网络科技有限公司',
            cups: 1800,
            rent: 100000,
            seller: 50000,
            mode: '固定月租、每月免费100杯、超出单杯5元',
            dutyName: '里斯本',
            status: 2,
            alarmType: 0,
          },
          {
            deviceUuid: '200017b8ead355',
            deviceName: '测试设备',
            customerName: '苏州三七二一网络科技有限公司',
            cups: 1800,
            rent: 100000,
            seller: 50000,
            mode: '固定月租、每月免费100杯、超出单杯5元',
            dutyName: '里斯本',
            status: 3,
            alarmType: 2,
          },
        ],
        pagination: {
          start: 0,
          curPage: 1,
          limit: 10,
          total: 0,
        },
      }
    },
    methods: {
      handleDeviceDetail(device) {
        this.$router.push({name: 'dealer.device.detail', params: device})
      }
    },
  }
</script>

<style lang="scss">
  .dealer-device {

    .online {
      color: $_theme_color;
    }

    .status-btn {
      color: white;
      padding: $padding-8;
      border-radius: 16px;
    }

    .online-bg {
      background: $_theme_color;
    }

    .offline {
      color: gray;
    }

    .offline-bg {
      background: gray;
    }

    .alarm {
      color: #E85655;
    }

    .alarm-bg {
      background: #E85655;
    }

    .more {
      color: $_theme_color;
      cursor: pointer;
    }


    .analyze-item {
      &-container {
        @include set_bg_color($-theme-light-9, $background-color-dark);
        padding: $padding;
        border-radius: $border-radius;
        min-height: 120px;

        &-title {
          font-size: 16px;
          font-weight: 500;
          border-bottom: 1px solid;
          padding-bottom: $padding-8;
          @include set_border_color($--border-color, $--border-color-dark);
        }
      }

      &-content {
        text-align: center;
        padding: $padding;

        .number {
          font-size: 20px;
          line-height: 1.7;
          font-weight: 500;
        }

        .label {
          font-size: 13px;
          @include set_font_color($--font-02-color, $--font-color-dark);
        }
      }
    }

    &-filter {
      padding: $padding $padding $padding 0;

      .input {
        display: flex;
        justify-content: flex-end;

        .el-input {
          width: 260px;
          margin-right: $margin;
        }

        .el-input__inner {
          @include set_bg_color_i($background-color-light, $--border-color-dark);
        }
      }
    }

    &-list {
      @include set_bg_color($background-color-light, $background-color-dark);
      border-radius: $border-radius;
      .el-table thead{
        background-color: $-theme-light-9;
      }
    }
  }
</style>
